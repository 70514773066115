@import url('https://fonts.googleapis.com/css?family=Bangers');

body {
  background: #eeeeee;
  font-family: awsconsole, Amazon Ember,Helvetica Neue,Roboto,Arial,sans-serif;
}

#outer-div {
 /* clear style */
}

.main-container {
  padding-left: 1.5rem;
}

#card-webcam {
    width: 720px;
    min-width: 720px;
    overflow:hidden;
    display:block;
    height: 720px;
    position: relative;
}

#card-webcam video, #card-webcam img {
  margin-left: -280px;
}

#video-overlay {
  position: absolute;
  left: 30px;
  top: 30px;
  width: 660px;
  height: 660px;
  border: 5px dashed red;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.65) 0px 5px 15px;
  }

#info-container {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  padding: 1.5rem;
  width: 100%;
  max-height: 720px;
  overflow-y: auto;
  background-color: #fff;
  box-shadow: 0 1px 1px 0 var(--awsui-color-shadow-medium,rgba(0,28,36,.3)),1px 1px 1px 0 var(--awsui-color-shadow-side,rgba(0,28,36,.15)),-1px 1px 1px 0 var(--awsui-color-shadow-side,rgba(0,28,36,.15));
  border-top: 1px solid #eaeded;
  border-top: 1px solid var(--awsui-color-border-container-top,#eaeded);
}

#info-container .amplify-heading {
  /*padding-bottom: 1.5rem;*/
}

#info-container .amplify-alert {
  margin-top: 1.5rem;
  background-color: #f1faff;
  border: 1px solid #0073bb;
  color: #000;
}

#info-container .amplify-icon {
  width: 2em !important;
  height: 2em !important;
  color: #0073bb; 
}

#info-container .amplify-icon svg{
  width: 2em !important;
  height: 2em !important;

}

.button-bar {
  padding: 1rem;
  width: 100%;
  text-align: center;
}

.button-bar .MuiBox-root {
  padding: 0.5rem;
  border: 1px solid #999;
  margin-left: 0.5rem;
  width: 30rem;
  display: inline-flex;
}

.button-bar .MuiBox-root p {
  width: 15rem;
}

.button-bar .MuiBox-root .MuiSlider-colorPrimary {
  padding-top: 2rem;
}

.icon-button {
  background-color: #fff;
  box-shadow: 0 1px 1px 0 var(--awsui-color-shadow-medium,rgba(0,28,36,.3)),1px 1px 1px 0 var(--awsui-color-shadow-side,rgba(0,28,36,.15)),-1px 1px 1px 0 var(--awsui-color-shadow-side,rgba(0,28,36,.15));

}

.icon-button:hover {
  box-shadow: rgba(0, 0, 0, 0.65) 0px 5px 5px;
}

.header {
  padding-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  text-align: center;
  border-bottom: 3px solid #FF9900;
  font-family: "Bangers", sans-serif;
  background-color: #232f3e;
  display: block;
}

.instruction-text {
  padding: 1.5rem 0 1.5rem 0;
}

.hint-box {
 border: 1px solid #999;
}

.hint-button-container {
  padding: 1rem 1rem 0 1rem;
  float: left;
}

.hint-text{
  background-color: #EEE;
  padding: 1rem;
  min-height: 7rem;
}

.hint-text p {
  margin: 0;
  padding: 0;
}